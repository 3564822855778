import React from 'react';

import Helmet from 'react-helmet';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { pb, pt, px, py, Padding } from 'styled-components-spacing';

import Card from '@nib/card';
import {
  FamilyGraphicIcon,
  AssessmentGraphicIcon,
  ClockGraphicIcon,
  PriceGraphicIcon,
  SingleGraphicIcon,
  CoupleGraphicIcon,
} from '@nib/icons';
import { Box, Section, Stack, Tiles } from '@nib/layout';

import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import colors from '@nib-components/colors';
import Copy, { Bold } from '@nib-components/copy';
import Link from '@nib-components/link';
import Heading from '@nib-components/heading';

import Layout from '../../components/Layout';
import piggybackCn200w from '../../img/health-insurance-cn/piggyback-cn200x200.png';
import piggybackCn400w from '../../img/health-insurance-cn/piggyback-cn400x400.png';
import metrics from '../../metrics';
import { urlConstants } from '../../constructs/constants';
import { StyledCard } from '../../components/styledComponents';
import CallBackModal from '../../components/CallBackModal';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'gridItem1'
    'gridItem3'
    'gridItem2';
  ${pb(6)};
  ${breakpoint('lg')`
    ${pb(8)};
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'gridItem1 gridItem3'
      'gridItem2 gridItem3'
  `};
`;

const GridItem1 = styled.div`
  grid-area: gridItem1;
  ${pb(4)};
  ${breakpoint('lg')`
    ${px(4)};
  `};
`;

const GridItem2 = styled.div`
  grid-area: gridItem2;
  text-align: center;
  ${pt(4)};
  ${breakpoint('lg')`
    text-align: left;
    ${px(4)};
  `};
`;

const GridItem3 = styled.div`
  grid-area: gridItem3;
  display: grid;
`;

const ValueImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  text-align: center;

  ${breakpoint('lg')`
    height: 100%;
    width: auto;
    text-align: left;
  `};
`;

const ValueImage = styled.img`
  height: auto;

  ${breakpoint('lg')`
    width: 100%;
    position: absolute;
    ${(props) =>
      !props.thin
        ? css`
            height: 100%;
            width: auto;
          `
        : css`
            width: 100%;
          `}
  `};
`;

const ButtonWrapper = styled.div`
  ${py(1)};
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${breakpoint('md')`
    display: block;
    ${py(6)};
  `};
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  margin-right: 10px;
`;

const cnMigrantJoin = `${urlConstants.cnJoin}&cs=Migrant`;

const ValueSection = (): JSX.Element => (
  <Section>
    <Grid>
      <GridItem1>
        <Stack space={{ xs: 2, md: 4 }}>
          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
            您和家人的医疗保险
          </Heading>
          <Copy measure={false}>
            我们可以说中文! 即刻致电了解我们的特别优惠，并获取由中文医疗保险专员提供的定制化报价。
          </Copy>
        </Stack>
      </GridItem1>
      <GridItem2>
        <ButtonWrapper>
          <StyledSecondaryButton href="/compare-plans">比较计划</StyledSecondaryButton>
          <PrimaryButton href={urlConstants.cnJoin}>获取报价</PrimaryButton>
        </ButtonWrapper>
        <Padding top={2}>
          <Copy small measure={false}>
            拨打0800 888 321，选择1。
          </Copy>
          <Copy small measure={false}>
            我们的服务时间为周一至周五早8点—晚7点。
          </Copy>
        </Padding>
      </GridItem2>
      <GridItem3>
        <ValueImageWrapper>
          <picture>
            <source media="(max-width:640px)" srcSet={`${piggybackCn200w}`} />
            <ValueImage src={piggybackCn400w}></ValueImage>
          </picture>
        </ValueImageWrapper>
      </GridItem3>
    </Grid>
    <Features />
  </Section>
);

const BoxWrapper = styled(Box)`
  box-sizing: border-box;
  text-align: center;
`;

interface FeatureTileProps {
  icon: any;
  title: string | JSX.Element;
}

const FeatureTile = ({ icon: Icon, title }: FeatureTileProps): JSX.Element => (
  <BoxWrapper
    padding={5}
    paddingVertical={{ lg: 6 }}
    height="100%"
    style={{ boxShadow: `${colors.sneezy} 0px 4px 8px 0px` }}
  >
    <Icon size="xl" fill="trueGreen" />
    <Padding top={4}>
      <Box textAlign="center">
        <Heading size={4} component="h3">
          {title}
        </Heading>
      </Box>
    </Padding>
  </BoxWrapper>
);

const Features = (): JSX.Element => (
  <div>
    <Box display="flex" justifyContent="center" marginBottom={4}>
      <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
        nib，医疗保险本应如此。
      </Heading>
    </Box>
    <Padding bottom={5}>
      <Tiles columns={{ xs: 1, md: 2, lg: 4 }} space={{ xs: 4, md: 6 }}>
        <FeatureTile
          icon={FamilyGraphicIcon}
          title="我们为一百多万新西兰及澳大利亚人提供保险保障"
        />
        <FeatureTile icon={AssessmentGraphicIcon} title="使您得以快速获取医疗服务。" />
        <FeatureTile
          icon={ClockGraphicIcon}
          title={
            <div>
              每个人、每对配偶，
              <br />
              每个家庭都能找到适合自身需求的保险方案。
            </div>
          }
        />
        <FeatureTile icon={PriceGraphicIcon} title="简便、智能，高性价比" />
      </Tiles>
    </Padding>
  </div>
);

const QuoteSection = (): JSX.Element => (
  <Section background="warmWhite60">
    <Stack space={4}>
      <Box display="flex" justifyContent="center" marginBottom={4}>
        <Heading
          color="trueGreen"
          size={{ sm: 4, md: 3, lg: 2 }}
          component="h2"
          style={{ textAlign: 'center' }}
        >
          适合您生活方式的保险方案才是最恰当的选择。
        </Heading>
      </Box>
      <Copy measure={false}>
        nib的保险方案既包括牙医、验光、骨科及针灸等日常医疗保障内容，也有应对例如癌症治疗、手术费用、术后护理等意外状况的保障内容，必有一种方案适合您。
      </Copy>
      <Tiles columns={{ xs: 1, md: 3 }} space={{ xs: 4, md: 6 }} flex>
        <StyledCard icon={SingleGraphicIcon} align="center">
          <Card.Content title="单身被保险人">
            <Copy>
              <Bold>单身医疗保险</Bold>
            </Copy>
            <Copy>从日常医疗需求保障到全面医院保险，您需要满足自身需求的高品质医疗保险方案。</Copy>
          </Card.Content>
          <Card.Footer align="center">
            <Copy>
              每周 $17.12 起<sup>2</sup>
            </Copy>
            <PrimaryButton href={cnMigrantJoin}>获取报价</PrimaryButton>
          </Card.Footer>
        </StyledCard>
        <StyledCard icon={CoupleGraphicIcon} align="center">
          <Card.Content title="配偶被保险人">
            <Copy>
              <Bold>配偶医疗保险</Bold>
            </Copy>
            <Copy>
              您与伴侣想要获得能保障一系列服务的保险，因此正在选择最能满足需求的保险方案。
            </Copy>
          </Card.Content>
          <Card.Footer>
            <Copy>
              每周 $37.58 起<sup>3</sup>
            </Copy>
            <PrimaryButton href={cnMigrantJoin}>获取报价</PrimaryButton>
          </Card.Footer>
        </StyledCard>
        <StyledCard icon={FamilyGraphicIcon} align="center">
          <Card.Content title="家庭被保险人">
            <Copy>
              <Bold>家庭医疗保险</Bold>
            </Copy>
            <Copy>
              您刚刚建立了家庭，但肯定还会添宝宝。您需要获得日常与生育相关医疗服务的保障。
            </Copy>
          </Card.Content>
          <Card.Footer>
            <Copy>
              每周 $51.20 起<sup>4</sup>
            </Copy>
            <PrimaryButton href={cnMigrantJoin}>获取报价</PrimaryButton>
          </Card.Footer>
        </StyledCard>
      </Tiles>
    </Stack>
  </Section>
);

const ContactFormSection = (): JSX.Element => (
  <>
    <Section>
      <Box display="flex" justifyContent="left" marginBottom={4} padding={2}>
        <Heading
          color="trueGreen"
          size={{ sm: 4, md: 3, lg: 2 }}
          component="h2"
          style={{ textAlign: 'left' }}
        >
          需要帮助吗？我们可以给您回电
        </Heading>
      </Box>
      <Box display="flex" justifyContent="left" marginBottom={4} padding={2}>
        <Copy measure={false}>
          如果您需要帮助来决定什么方案最适合您，请给我们打电话。在以下的时间内选择一个，通常在一个工作日内（星期一至星期五上午8点至下午5点），我们友好的顾问将会给您回电。或者，立即与顾问交谈，拨打0800
          888 321，选择1。
        </Copy>
      </Box>
      <CallBackModal variant="ExpertCN" isModal={false} />
    </Section>
  </>
);

const DocumentSection = (): JSX.Element => (
  <Section>
    <Box padding={4} borderRadius="standard">
      <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
        <Link href="#things-to-know">Important things to know</Link>
      </Heading>
      <Copy measure={false} component="div">
        <ol style={{ paddingLeft: '1rem' }}>
          <li>
            本情况概览并非保险单据，而是nib保险方案主要特点与赔付内容的总结。保险单据中另含对赔付内容、例外状况、相关等待期的完整阐述。在我司文件页document
            page可以查阅这些保单文件。
          </li>
          <li>
            举例来说,
            一位27岁不吸烟女性、nib标准医院及标准日常医疗保险方案，含每年超额保险额度$500。报价中包含4%直接付款折扣。
          </li>
          <li>
            举例来说,一对配偶，双方均为30岁不吸烟成年人，
            nib标准医院及高级日常医疗保险方案，含每年超额保险额度$500。报价中包含4%直接付款折扣。
          </li>
          <li>
            举例来说,一个家庭，包含两位成年人（35岁不吸烟）及两名子女（21岁以下），nib高级医院及高级日常医疗保险方案，含每年超额保险额度$500。报价中包含4%直接付款折扣。
          </li>
        </ol>
      </Copy>
    </Box>
  </Section>
);

const schema = {
  '@context': 'https://schema.org',
  '@type': 'WebPage',
  '@id': 'WebPage',
  identifier: 'https://www.nib.co.nz/offer/health-insurance-cn',
  url: 'https://www.nib.co.nz/offer/health-insurance-cn',
  description: 'nib，医疗保险本应如此',
  name: ['您和家人的医疗保险 | nib'],
  isPartOf: 'https://www.nib.co.nz',
};
const JSONschema = JSON.stringify(schema);

const HealthInsuranceCNPage = (): JSX.Element => (
  <Layout>
    <Helmet>
      <title>您和家人的医疗保险 | nib</title>
      <meta name="description" content="nib，医疗保险本应如此" />
      <script type="application/ld+json">{JSONschema}</script>
    </Helmet>
    <ValueSection />
    <QuoteSection />
    <ContactFormSection />
    <DocumentSection />
  </Layout>
);

export default metrics({ pageName: 'health-insurance-cn' })(HealthInsuranceCNPage);
